<template lang="pug">
.privacyPage
  h1.privacyPage-title Privacy policy
  .privacyPage-text
    | Last Updated: 08/30/2023<br/><br/>

    | This Privacy Policy outlines how ian.realestate collects, uses, discloses, and safeguards personal information when you visit and interact with our website ian.realestate (the "Website"). By using the Website, you consent to the practices described in this Privacy Policy.<br/><br/>

    | <b>1. Information We Collect</b><br/><br/>

    | We may collect various types of information, including but not limited to:<br/><br/>

    | Personal Information: This includes information that can personally identify you, such as your name, email address, postal address, and phone number. We collect this information only if you voluntarily provide it to us, such as when you sign up for our newsletter or create an account.<br/><br/>

    | Usage Information: We collect non-personal information about your interactions with the Website, such as your IP address, browser type, device type, referring website, pages visited, and the date and time of your visit. This information helps us improve the functionality and performance of the Website.<br/><br/>

    | Cookies and Tracking Technologies: We use cookies, web beacons, and similar technologies to collect information about your browsing behavior on the Website. You can manage your cookie preferences through your browser settings.<br/><br/>

    | <b>2. How We Use Your Information</b><br/><br/>

    | We use the collected information for the following purposes:<br/><br/>

    | Providing and improving our services.<br/>
    | Responding to your inquiries and requests.<br/>
    | Sending you promotional materials, newsletters, and updates (if you've opted in).<br/>
    | Analyzing and improving the Website's performance and user experience.<br/>
    | Protecting our legal rights and preventing misuse of the Website.<br/><br/>

    | <b>3. Disclosure of Information</b><br/><br/>

    | We may share your information in the following circumstances:<br/><br/>

    | With service providers who assist us in running the Website and providing services to you.<br/>
    | With your consent, to third parties for marketing or other purposes.<br/>
    | When required by law or to protect our legal rights.<br/><br/>

    | <b>4. Data Security</b><br/><br/>

    | We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmitting data over the internet is completely secure, and we cannot guarantee the absolute security of your information.<br/><br/>

    | <b>5. Your Choices</b><br/><br/>

    | You can exercise certain rights regarding your personal information, such as the right to access, correct, or delete your data. You may also opt out of receiving marketing communications from us at any time.<br/><br/>

    | <b>6. Third-Party Links</b><br/><br/>

    | Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of those third parties before providing any personal information.<br/><br/>

    | <b>7. Changes to this Privacy Policy</b><br/><br/>

    | We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated version will be posted on the Website, and the date of the latest revision will be indicated.<br/><br/>

    | <b>8. Contact Us</b><br/><br/>

    | If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at <a href="mailto:ian.hurdle@theagencyre.com">ian.hurdle@theagencyre.com</a>.<br/>
</template>

<script>
  export default {
    mounted() {
      this.$store.commit('pageLoaded');
    },
  };
</script>

<style lang="scss">
  .privacyPage {
    display: flex;
    flex-direction: column;
  }
  .privacyPage-title {
    font-size: 36px;
    font-family: Proto Grotesk Web;
    line-height: 43px;
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
    @include tablet {
      font-size: 24px;
      padding-top: 30px;
    }
  }

  .privacyPage-text {
    padding: 50px 0;
    @include tablet {
      font-size: 16px;
      padding: 30px 0;
    }
  }
</style>
